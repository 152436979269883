import React, { Component } from 'react'
import styled from 'styled-components'

import { ReactComponent as MOLogo } from 'assets/mossy-oak-logo.svg'
import { ReactComponent as HausLogo } from 'assets/haus-logo.svg'
import { ReactComponent as Walmart } from 'assets/walmart-logo.svg'
import { ReactComponent as LOBLogo } from 'assets/life-of-beauty-logo.svg'

export default class Logos extends Component {
  render() {
    const { title } = this.props
    return (
      <Container>
        <Heading>{title}</Heading>
        <Divider />
        <LogoContainer>
          <Logo1 />
          <Logo2 />
          <Logo3 />
          <Logo4 />
        </LogoContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  padding: 25px 50px 30px;
  color: ${props => props.theme.primaryColor};
`
const Heading = styled.h1`
  margin: 0;
  font-size: 30pt;
  width: 100%;
  text-align: center;
  letter-spacing: -2px;
  color: ${props => props.theme.primaryColor};
  font-weight: ${props => props.theme.ultra};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 20pt;
  }
`
const Divider = styled.div`
  width: 100px;
  height: 8px;
  background-color: ${props => props.theme.primaryColor};
  margin: 20px auto 40px;

  @media (max-width: ${props => props.theme.tablet}px) {
    margin: 20px auto 15px;
  }
`
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-wrap: wrap;
  }
`
const Logo1 = styled(MOLogo)`
  width: 15vw;
  height: 70px;
  margin: 15px 0;

  & .wm-1 {
    fill: #007dc6;
  }
  & .wm-2 {
    fill: #ffc220;
  }
  & .mo-1 {
    fill: #c7c2a3;
  }
  & .mo-2 {
    fill: #231f20;
  }
  & .mo-3 {
    fill: #00461c;
  }
  & .mo-4 {
    fill: #b13427;
  }
  & .haus-1 {
    fill: #d25227;
  }
  & .lob-1 {
    fill: #ae9941;
  }
  & .lob-2 {
    fill: #fdfdfd;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 30vw;
    height: 60px;
  }
`
const Logo2 = Logo1.withComponent(HausLogo)
const Logo3 = Logo1.withComponent(Walmart)
const Logo4 = Logo1.withComponent(LOBLogo)
