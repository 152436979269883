import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Header from 'components/Header'
import ContentSection from 'components/ContentSection'
import Logos from './Logos'
import ImageCards from './ImageCards'
import SEO from 'components/SEO'
import Helmet from 'react-helmet'
import NavBar from 'components/NavBar'
import Layout from 'components/Layout'

export const HomePageTemplate = ({ header, services, section1, section2 }) => (
  <Fragment>
    <Header
      images={header.images}
      title={header.title}
      subtitle={header.subtitle}
      anchor={header.scrollAnchor}
    />
    <ImageCards cards={services} anchor={header.scrollAnchor} />
    <ContentSection
      image={section1.image.childImageSharp}
      title={section1.title}
      subtitle={section1.subtitle}
      text={section1.text}
      button={{
        path: section1.buttonPath,
        text: section1.buttonText
      }}
    />
    <Logos title={section2.title} />
  </Fragment>
)

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, structuredData } = frontmatter
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
        structuredData={structuredData}
      />
      <Helmet title={seo.title} />
      <NavBar />
      <HomePageTemplate
        header={frontmatter.header}
        services={frontmatter.services}
        section1={frontmatter.section1}
        section2={frontmatter.section2}
      />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seo {
          title
          description
          url
        }
        structuredData {
          context
          type
          url
          name
          address
          telephone
          priceRange
          additionalProperty
          openingHours
          areaServed
          email
          legalName
          alternateName
          description
        }
        header {
          images {
            mobile {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            desktop {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          title
          subtitle
          scrollAnchor
        }
        services {
          number
          title
          subtitle
          path
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        section1 {
          image {
            childImageSharp {
              sizes(maxWidth: 2000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          title
          subtitle
          text
          buttonPath
          buttonText
        }
        section2 {
          title
        }
      }
    }
  }
`
